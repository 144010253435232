import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Badge, ListGroup, ListGroupItem, Card, CardBody, CardTitle } from "reactstrap";
import Loading from '../components/Loading';
import { Link } from 'react-router-dom';
import UseClotereCard from "./UseClotereCard";
import WantUseClotereCard from "./WantUseClotereCard";
import { useParams } from 'react-router-dom';
import { Navbar } from '../Navbar.js';
import Footer from '../components/Footer';
import icnNotaire from '../img/icn-notaire.svg';
import { faLink, faPhone, faLocationDot, faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import { Fiche, PhotoProfil } from "../style/Annuaire.js";
import { Helmet } from "react-helmet";
import { PopupButton } from '@typeform/embed-react';
import { ChevronRightIcon, InformationCircleIcon,ArrowRightIcon } from '@heroicons/react/20/solid'



function FicheNotaire(args) {
    const [notaire, setNotaire] = useState(null);
    const [isLoading, setLoading] = useState(true);
    const [data, setData] = useState([]);


    const { nom } = useParams();

    const SPREADSHEET_ID = '1goMuEIM_a2g9k0jivUC4t0ABw5ABLaX1QSLvJwnGlgk';
    const API_KEY = 'AIzaSyDOypSeMIO3bhhlZt9-2KZ7OKpQW1n-njg';
    const RANGE = 'France';


    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(
                    `https://sheets.googleapis.com/v4/spreadsheets/${SPREADSHEET_ID}/values/${RANGE}?key=${API_KEY}`
                );
                const rows = response.data.values;
                //console.log("ici", rows);
                setData(rows);
            } catch (error) {
                console.error('Error fetching data: ', error);
            }
        };

        fetchData();

    }, []);


    useEffect(() => {
        if (data.length > 1) {
            const headers = data[0];
            const rows = data.slice(1);
            const formatted = rows.map(row => {
                const obj = {};
                headers.forEach((header, index) => {
                    obj[header] = row[index] || '';
                });
                return obj;
            });


            if (formatted != null) {
                var filteredData = formatted.filter(item => item.lien_fiche === nom);

                if (filteredData.length > 0) {
                    if (filteredData[0].id_airtable !== null) {
                        const URL = `https://api.airtable.com/v0/appD48APNaGA4GN0B/user/${filteredData[0].id_airtable}`;

                        fetch(
                            URL,
                            {
                                method: "GET",
                                headers: {
                                    Authorization: "Bearer patfRIUbOM9xqwLV2.dfbc9a305f2124aff75634c819a8335ecd984b1d19e98f67f14013378ed6bb02",
                                    'content-type': 'application/x-www-form-urlencoded',
                                    "Accept": "application/json, text/plain, /"
                                },
                            })
                            .then((res) => res.json())
                            .then((res) => {
                                var notaireAirtable = res.fields;
                                console.log("res",res);
                                setNotaire({ ...notaireAirtable, ...filteredData[0] });

                            })
                            .catch((error) => console.log(error));
                    }
                }
            }
        }
    }, [data]);


    useEffect(() => {
        //console.log(notaire);
        if (notaire != null) {
            setLoading(false);
        }
    }, [notaire]);


    if (isLoading) {
        return <Loading />;
    }
    return (
        <>
            <Helmet>
                <title>Maitre {notaire.nom} - notaire à {notaire.ville}</title>
            </Helmet>
            <div className="bg-gradient-to-t from-teal-100 to-teal-50">
                <Container>
                <Navbar
            user={args.user}
          />

                    <div className="px-6 py-8 sm:pt-12 pb-12 lg:px-8">
                        <div className="mx-auto text-center">
                            <nav aria-label="Breadcrumb" className="flex justify-center">
                                <ol role="list" className="flex items-center space-x-4">
                                    <li>
                                        <div>
                                            <Link to="/" className="text-gray-400 hover:text-gray-500">
                                                Annuaire des notaires de France
                                            </Link>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="flex items-center">
                                            <ChevronRightIcon aria-hidden="true" className="h-5 w-5 flex-shrink-0 text-gray-400" />
                                            <Link to={`/fr/notaires/ville/${notaire.ville}`} className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">
                                                Notaires à {notaire.ville}
                                            </Link>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="flex items-center">
                                            <ChevronRightIcon aria-hidden="true" className="h-5 w-5 flex-shrink-0 text-gray-400" />
                                            <Link
                                                to="/fr/notaires"
                                                className="ml-4 text-sm font-medium text-emerald-800 hover:text-gray-700"
                                            >
                                                {notaire.nom}
                                            </Link>
                                        </div>
                                    </li>
                                </ol>
                            </nav>
                            <div className="flex justify-center items-center">
                                <div className="mr-4 flex-shrink-0 self-end">
                                    {notaire.photo_profil_from_notaire ? <>
                                        <PhotoProfil>
                                            <img src={notaire.photo_profil_from_notaire[0].url} alt={notaire.nom} />
                                        </PhotoProfil>
                                    </> : <>
                                        <img src={icnNotaire} alt="notaire" />
                                    </>}
                                </div>
                                <div>
                                    <h1><small>Maître {notaire.nom}</small></h1>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="px-6 py-2 sm:pt-12 pb-12 lg:px-8 flex justify-center">
                        {notaire.utilise_clotere === "oui" ? <>
                            <UseClotereCard idnotaire={notaire.airtable_id} />
                        </> : <>
                            <div className="rounded-md bg-blue-50 p-4 sm:w-1/2 ">
                                <div className="flex">
                                    <div className="flex-shrink-0">
                                        <InformationCircleIcon aria-hidden="true" className="h-5 w-5 text-blue-400" />
                                    </div>
                                    <div className="ml-3 flex-1 md:flex md:justify-between">
                                        <p className="text-sm text-blue-700 mb-0">Ce notaire n'utilise pas le service de dossier en ligne Clotere</p>

                                    </div>
                                </div>
                            </div>
                        </>}
                    </div>
                </Container>
                <Fiche>
                    <Container>
                        <Row>
                            <Col className='col-xl-8 offset-xl-2 col-xs-12 col-lg-12'>
                                <Card>
                                    <CardTitle>Coordonnées</CardTitle>
                                    <CardBody>
                                        <ListGroup>
                                            <ListGroupItem>
                                                <FontAwesomeIcon icon={faLocationDot} className='mr-3 rounded' />
                                                <p>{notaire.adresse} {" "} {notaire.cp}{" "} {notaire.ville}</p>
                                            </ListGroupItem>
                                            <ListGroupItem>
                                                <FontAwesomeIcon icon={faPhone} className='mr-3 rounded' />
                                                {notaire.telephone ? <>
                                                    <p>{notaire.telephone}</p>
                                                </> : <>
                                                    <p className="text-muted">aucun téléphone renseigné</p>
                                                </>}
                                            </ListGroupItem>
                                            {notaire.site &&
                                                <>
                                                <ListGroupItem>
                                                    <FontAwesomeIcon icon={faLink} className='mr-3 rounded' />
                                                    <a href={notaire.site} target="blank">Voir le site</a>
                                                </ListGroupItem>
                                                </>
                                            }

                                        </ListGroup>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        {notaire.utilise_clotere === "oui" ? <>
                            <Row className='mt-3'>
                                <Col className='col-xl-8 offset-xl-2 col-xs-12 col-lg-12'>
                                    <Card>
                                        <CardTitle>Présentation</CardTitle>
                                        <CardBody>
                                            <p className='presentation'>
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html: notaire.presentation_from_notaire
                                                    }}></div>
                                            </p>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                            <Row className='mt-3'>
                                <Col className='col-xl-8 offset-xl-2 col-xs-12 col-lg-12'>
                                    <Card>
                                        <CardTitle>Domaines de compétences</CardTitle>
                                        <CardBody className='p-0'>
                                            <div className='col-competences'>
                                                {notaire.domaine_competences_from_notaire.length ? <>
                                                    {notaire.domaine_competences_from_notaire.map((col, i) => (
                                                        <>
                                                            <Badge className='competence'>{notaire.domaine_competences_from_notaire[i]}</Badge>
                                                        </>
                                                    ))}
                                                </> : <>
                                                    Non renseigné
                                                </>}


                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                            <Row className='mt-3'>
                                <Col className='col-xl-8 offset-xl-2 col-xs-12 col-lg-12'>
                                    <Card>
                                        <CardTitle>Services</CardTitle>
                                        <CardBody>
                                            <ListGroup>
                                            <ListGroupItem>
                                                    <FontAwesomeIcon icon={faCheck} />
                                                    Suivi de dossier en ligne
                                                </ListGroupItem>
                                                <ListGroupItem>
                                                    <FontAwesomeIcon icon={faCheck} />
                                                    Transfert de documents sécurisés
                                                </ListGroupItem>
                                                {notaire.whatsapp_from_info_notaire &&
                                                <>
                                                    <ListGroupItem>
                                                        <FontAwesomeIcon icon={faCheck} />
                                                       Échange via whatsapp 
                                                    </ListGroupItem>
                                                </>
                                                }
                                                {notaire.visio_from_info_notaire &&
                                                <>
                                                    <ListGroupItem>
                                                        <FontAwesomeIcon icon={faCheck} />
                                                       Réunion en visio-conférence
                                                    </ListGroupItem>
                                                </>
                                                }
                                                {notaire.signature_from_info_notaire &&
                                                <>
                                                    <ListGroupItem>
                                                        <FontAwesomeIcon icon={faCheck} />
                                                       Signature en ligne
                                                    </ListGroupItem>
                                                </>
                                                }
                                            </ListGroup>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                            <Row className='mt-3'>
                                <Col className='col-xl-8 offset-xl-2 col-xs-12 col-lg-12 mb-5' align="center">
                                    <PopupButton
                                        id="cDphxVXu"
                                        size={100}
                                        hidden={{ idnotaire: notaire.airtable_id }}
                                        autoClose={false}
                                        style={{ border: '0' }}
                                    >
                                        <button className='btn-main'>Contacter ce notaire <ArrowRightIcon aria-hidden="true" className="-mr-0.5 h-5 w-5" /></button>
                                    </PopupButton>

                                </Col>
                            </Row>
                        </> : <>
                            <Row className='mt-3'>
                                <Col className='col-md-8 offset-md-2 col-xl-8 offset-xl-2 col-xs-12 col-lg-8 offset-lg-2'>
                                    <WantUseClotereCard nom={notaire.nom} prenom={notaire.prenom} />
                                </Col>
                            </Row>

                        </>}



                    </Container>

                </Fiche>
                <Footer />
            </div>
        </>

    );
}

export default FicheNotaire;