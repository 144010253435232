import React from "react";
import Navbar from './components/Navbar';
/** composants **/
import HeaderNew from './components/HeaderNew';
import Efficacite from './components/Efficacite';
import FeatureParallax2 from './components/FeatureParallax2';
import EnMoyenne from './components/EnMoyenne';
import Faq from './components/Faq';
import BlocCta from './components/BlocCta';
import Footer from './components/Footer';



function Home(args) {
    return (
        <>
        <div>
            <Navbar user={args.user} />
            <HeaderNew/>
            <Efficacite/>
            <FeatureParallax2/>
            <EnMoyenne/>
            <Faq/>
            <BlocCta/>
            <br/><br/><br/>
            <Footer/>
        </div>
        </>
    );
}

export default Home;