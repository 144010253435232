import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Navbar } from '../Navbar.js';
import Footer from '../components/Footer';
import ListeArticles from "./ListeArticles";
import { Gradient } from "../annuaire/Gradient";
import { Container } from "../annuaire/Container";
import { ChevronRightIcon } from '@heroicons/react/16/solid';

const ListeArticlesS = styled.div`
background:linear-gradient(45deg,#fffbf2,#edfffd);
`;
const posts = [
    {
        id: 1,
        title: 'Boost your conversion rate',
        href: '#',
        description:
            'Illo sint voluptas. Error voluptates culpa eligendi. Hic vel totam vitae illo. Non aliquid explicabo necessitatibus unde. Sed exercitationem placeat consectetur nulla deserunt vel. Iusto corrupti dicta.',
        imageUrl:
            'https://images.unsplash.com/photo-1496128858413-b36217c2ce36?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3603&q=80',
        date: 'Mar 16, 2020',
        datetime: '2020-03-16',
        category: { title: 'Marketing', href: '#' },
        author: {
            name: 'Michael Foster',
            role: 'Co-Founder / CTO',
            href: '#',
            imageUrl:
                'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
        },
    },
    // More posts...
]


function Blog(args) {

    return (
        <>
            {/*header*/}
            <div className="relative rounded-4xl ">
                <Gradient className="absolute inset-2 bottom-0 rounded-4xl ring-1 ring-inset ring-black/5" />
                <Container className="relative">
                    <Navbar
                        user={args.user}
                        banner={
                            <Link
                                to="/demo"
                                className="no-underline flex items-center gap-1 rounded-full bg-fuchsia-950/35 px-3 py-0.5 text-sm/6 font-medium text-white data-[hover]:bg-fuchsia-950/30"
                            >
                                👋 Votre notaire en clair ! Trouvez les réponses à vos questions sur notre blog
                                <ChevronRightIcon className="size-4" />
                            </Link>
                        }
                    />

                    <div className="pb-0 pt-14 sm:pb-22 sm:pt-14 md:pb-38 md:pt-22 text-center">
                        <h1 className="text-[40px] sm:text-[80px] md:text-[80px] font-display  font-medium tracking-tight text-gray-950">
                            Votre notaire en <span className="text-[#1cf36c]">clair</span>
                        </h1>
                        <p className="text-[20px] sm:text-[23px]  mt-8 font-medium text-gray-950/75 ">
                            On vous explique simplement votre passage chez le notaire pour vous faire gagner en sérénité <span role="img">🧘</span>.
                        </p>

                    </div>

                    {/** section liens rapides */}
                    <div className="pb-14 pt-0 sm:pb-22 sm:pt-14 md:pb-38 md:pt-22 text-center">

                        <p className="text-[18px] sm:text-[18px]  mt-8 font-medium text-gray-950/75 ">
                            Une question ? Une réponse 👇
                        </p>
                        <div class="flex flex-row gap-4 flex-wrap justify-center">
                            <Link className="tag-recherche-notaire" to="/fr/notaires/ville/paris">Qu'est-ce que les frais de notaires ?</Link>
                            <Link className="tag-recherche-notaire" to="/fr/notaires/ville/lyon">À quel moment dois-je payer les frais de notaire ?</Link>
                            <Link className="tag-recherche-notaire" to="/fr/notaires/ville/lille">Suis-je obligé de prendre un notaire pour mon achat immobilier ?</Link>
                            <Link className="tag-recherche-notaire" to="/fr/notaires/ville/bordeaux">Les clauses suspensives qu'est-ce que c'est ?</Link>
                            <Link className="tag-recherche-notaire" to="/fr/notaires/ville/rennes">Puis-je changer de notaire en cours de transaction ?</Link>

                        </div>
                    </div>
                </Container>
            </div>
            {/** content */}
            <ListeArticlesS className="p-5">
                <ListeArticles title="Tous nos articles sur les notaires, leurs rôles et leurs frais." />
            </ListeArticlesS>
            <Footer />

        </>
    );
}

export default Blog;