import React from "react";
import { Link } from "react-router-dom";
import { Navbar } from '../Navbar.js';
import { Container } from "../annuaire/Container";
import { ChevronRightIcon } from '@heroicons/react/16/solid';



function HeaderPost(args) {

    return (
        <>
            <div className="bg-gradient-to-b from-teal-300 to-teal-50">
        <Container>
          <Navbar
            user={args.user}
          />

          <div className="px-6 py-8 sm:pt-12 pb-12 lg:px-8">
            <div className="mx-auto text-center">
              <nav aria-label="Breadcrumb" className="flex justify-center">
                <ol role="list" className="flex items-center space-x-4">
                  <li>
                    <div>
                      <Link to="/" className="text-gray-400 hover:text-gray-500">
                        Clotere
                      </Link>
                    </div>
                  </li>
                  <li>
                    <div className="flex items-center">
                      <ChevronRightIcon aria-hidden="true" className="h-5 w-5 flex-shrink-0 text-gray-400" />
                      <Link
                        to="/blog"
                        className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                      >
                       Votre notaire en clair
                      </Link>
                    </div>
                  </li>
                  <li>
                    <div className="flex items-center">
                      <ChevronRightIcon aria-hidden="true" className="h-5 w-5 flex-shrink-0 text-gray-400" />
                      
                        {args.titre}
                    </div>
                  </li>
                </ol>
              </nav>
              <h1 className="mt-2 text-4xl font-bold text-gray-900 sm:text-5xl">
              {args.titre}
              </h1>
            </div>
          </div>
        </Container>
        </div>

        </>
    );
}

export default HeaderPost;