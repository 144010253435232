import React, { useState, useEffect } from "react";
import { useMemberstack, } from "@memberstack/react";
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col, ListGroup, ListGroupItem } from "reactstrap";

import styled from "styled-components";
import logoClotere from "../img/logo-clotere.svg";
import { CheckCircleIcon } from '@heroicons/react/20/solid'
import { Link } from 'react-router-dom';
import { Widget } from '@typeform/embed-react';

const Logo = styled.img`
width:130px;
`;
const HomeS = styled.div`
min-height: 100vh;
.avantage,.formulaire{padding:6rem 4rem;min-height: 100vh;}
.formulaire{background-color:#fff!important;}
.mobile{display:none;}
.alert{border:0;margin:1rem 0;}
@media all and (max-width: 768px) {
    .avantage{
        display:none;
    }
    .mobile{
        display:block;
        
        img{display:none;}
    }
    .mobile,.formulaire{
        padding:2rem;
    }
    .formulaire{
        min-height: auto;
    }
`;
const ListGroupAvantages = styled(ListGroup)`
border:0;
li.list-group-item{
    border:0;
    margin:.5rem;
    background-color: transparent;
    display: flex;
    align-items: center;
    font-weight:bold;
    span{
        margin-right:10px;
        background-color:#FFF;
        padding:1rem;
        border-radius:10px;
        margin-right:15px;
    }
}
`;
const WidgetTypeform = styled(Widget)`
width:100%;
height:600px;

`;

function Onboard(args, props) {
    const memberstack = useMemberstack();
    const [member, setMember] = useState(null);
    const navigate = useNavigate();

    //redirect if already logged
    useEffect(() => {
        memberstack.getCurrentMember()
            .then(({ data: member }) => setMember(member))
    }, []);

    if (member) {
        navigate("/app/dashboard");
    }

    return (
        <>
            {!member && (
                <div>
                    <HomeS className="bg-gradient-to-t from-teal-100 to-teal-50">
                        <Row>
                            <Col md="5" className="avantage">
                                <Container>
                                    <div className="lg:pr-8 lg:pt-4">
                                        <div className="lg:max-w-lg">
                                            <Link to="/"><Logo alt="Clotere"
                                                src={logoClotere}></Logo></Link><br/>
                                            <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-2xl">Simplifiez votre passage chez le notaire</p>
                                            <p className="mt-6 text-lg leading-8 text-gray-600">
                                                Suivez l'avancement de votre dossier chez le notaire à un seul et même endroit.
                                            </p>
                                            <dl className="grid grid-cols-1 gap-x-8 gap-y-8 lg:gap-y-8">
                                                <div className="relative pl-16">
                                                    <dt className="text-base font-semibold leading-7 text-gray-900">
                                                        <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-emerald-600">
                                                            <CheckCircleIcon aria-hidden="true" className="h-6 w-6 text-white" />
                                                        </div>
                                                        Suivez l'avancement de votre transaction immobilère simplement.
                                                    </dt>
                                                </div>
                                                <div className="relative pl-16">
                                                    <dt className="text-base font-semibold leading-7 text-gray-900">
                                                        <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-emerald-600">
                                                            <CheckCircleIcon aria-hidden="true" className="h-6 w-6 text-white" />
                                                        </div>
                                                        Trouvez un notaire plus rapidement.
                                                    </dt>
                                                </div>
                                                <div className="relative pl-16">
                                                    <dt className="text-base font-semibold leading-7 text-gray-900">
                                                        <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-emerald-600">
                                                            <CheckCircleIcon aria-hidden="true" className="h-6 w-6 text-white" />
                                                        </div>
                                                        Fixez vos rendez-vous simplement avec votre notaire.
                                                    </dt>
                                                </div>
                                            </dl>
                                        </div>
                                    </div>
                                </Container>
                            </Col>

                            <Col md="7" xs="0" className="formulaire">
                            <WidgetTypeform id="JM2MTsDD"  className="" />
                            </Col>
                            <Col xs="12" className="avantage mobile">
                                <Container>
                                    <Logo alt="Clotere"
                                        src={logoClotere}></Logo>
                                    <br /><br />
                                    <h4>Clotere vous facilite le passage chez le notaire</h4>
                                    <ListGroupAvantages>
                                        <ListGroupItem>
                                            <span>🥳</span> Suivez votre transaction immobilière facilement
                                        </ListGroupItem>
                                        <ListGroupItem>
                                            <span>👌</span> Regroupez tous vos échanges au même endroit
                                        </ListGroupItem>
                                        <ListGroupItem>
                                            <span>🔥</span> Trouvez un notaire rapidement
                                        </ListGroupItem>
                                        <ListGroupItem>
                                            <span>📆</span> Fixer vos rendez-vous avec votre notaire
                                        </ListGroupItem>
                                        <ListGroupItem>
                                            <span>📨</span> Réalisez vos signatures d'acte en ligne de manière sécurisée
                                        </ListGroupItem>
                                    </ListGroupAvantages>
                                </Container>
                            </Col>
                        </Row>
                    </HomeS>
                </div>
            )}
        </>
    );
}

export default Onboard;