import React from "react";
const posts = [
    {
      id: 1,
      title: 'Comprendre les frais de notaire dans l\'immobilier',
      href: 'https://clotere.webflow.io/post/les-frais-de-notaire-dans-limmobilier',
      description:"Allant jusqu'à 6% du prix d'achat de votre bien, les frais de notaire représente un poste de dépense important. Bien anticipé ce coût est important, on vous explique tout sur ces frais de notaire.",
       imageUrl:
        'https://cdn.prod.website-files.com/667abe8623fe3d0e75289a62/668d8c483cf92186b04e6edf_Frame-35.webp',
      category: { title: 'Immobilier', href: '#' },
    },
    {
      id: 2,
      title: 'Les clauses suspensives, finalement qu\'est-ce que c\'est ?',
      href: 'https://clotere.webflow.io/post/les-clauses-suspensives-finalement-quest-ce-que-cest',
      description:"Si vous achetez un bien immobilier vous entendrez forcément parlé des clauses suspensives 🤔 ! Ne vous inquiétez pas c'est très simple, on vous explique tout dans cet article.",
       imageUrl:
        'https://cdn.prod.website-files.com/667abe8623fe3d0e75289a62/668d463e4cb22c2895a3e58a_Frame-33.webp',
      category: { title: 'Immobilier', href: '#' },
    },
    {
      id: 3,
      title: 'Comment choisir son notaire pour son achat immobilier ?',
      href: 'https://clotere.webflow.io/post/comment-choisir-son-notaire-pour-son-achat-immobilier',
      description:"Acquérir un bien immobilier requiert toujours l’intervention d’un notaire, au moins pour réaliser l’acte de vente. Comment faire le choix d’un notaire pour un achat immobilier ? Découvrez nos conseils.",
       imageUrl:
        'https://cdn.prod.website-files.com/667abe8623fe3d0e75289a62/668befc3325f928487de5d9d_Frame-18.webp',
      category: { title: 'Immobilier', href: '#' },
    },
    // More posts...
  ]


function Articles(args) {

    return (
        <>
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-3 ">
          {posts.map((post) => (
            <article key={post.id} className="flex flex-col items-start justify-between overflow-hidden bg-white shadow sm:rounded-lg p-3">
              <div className="relative w-full">
                <img
                  alt=""
                  src={post.imageUrl}
                  className="aspect-[16/9] w-full rounded-2xl bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2]"
                />
                <div className="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10" />
              </div>
              <div className="max-w-xl">
                <div className="mt-8 flex items-center gap-x-4 text-sm">
                  <p
                    className="relative z-10 rounded-full bg-emerald-50 px-3 py-1.5 font-medium text-emerald-600 hover:bg-gray-100"
                  >
                    {post.category.title}
                  </p>
                </div>
                <div className="group relative">
                  <h3 className="mt-3 text-xl font-semibold leading-7 text-gray-900 group-hover:text-gray-600">
                    <a href={post.href} target='blank' className="mt-3 text-xl font-semibold leading-7 text-gray-900 group-hover:text-gray-600 no-underline">
                      <span className="absolute inset-0" />
                      {post.title}
                    </a>
                  </h3>
                  <p className="mt-0 text-md leading-6 text-gray-600">{post.description}</p>
                </div>
              </div>
            </article>
          ))}
        </div>
                    </>
    );
}

export default Articles;